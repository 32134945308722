import {
	Button,
	Cell,
	Dialog,
	Form,
	FormFiller,
	HeaderCell,
	Icon,
	Input,
	OwnTable,
	RouterHistory,
	Spinner,
	SwipeContainer,
	SwipePage,
	TableBody,
	TableHeader,
	TableRow,
} from "@klumpp/tools";
import { PropertyButton } from "@klumpp/tools/dist/components/OwnTable/Table";
import dayjs from "dayjs";
import React, { Component } from "react";
import { House, OperatingCostsCategory, User } from "../../../types/ObjectTypes";
import { formatDate } from "../../../utils/Utils";
import BasePage from "../../dashboard/BasePage";
import "./Home.scss";

export interface HomeProps {}

interface OcDialogInformation {
	ocId?: number;
	dateFrom: number;
	dateTo: number;
	price: number;
}

export interface HomeState {
	house: House;
	occs: OperatingCostsCategory[];
	loading: boolean;
	hoccLoading: boolean;
	graphHeight: number;
	graphWidth: number;
	newOCConsumption: boolean;
	renters: User[];
	rentersLoading: boolean;
	renterConsumptionLoading: boolean;
	renterConsumptions: any[];
	totalConsumption: number;
	ocInformation?: OcDialogInformation;
	ocLoading: boolean;
	changeKey: string;
}

export default class Home extends Component<HomeProps, HomeState> {
	timeout?: NodeJS.Timeout = undefined;
	occRef = React.createRef<SwipeContainer>();
	state = {
		occs: [],
		totalConsumption: 0,
		house: {} as House,
		loading: true,
		occPage: "occ-creation",
		hoccLoading: false,
		graphHeight: 300,
		graphWidth: 300,
		newOCConsumption: false,
		renters: [],
		rentersLoading: false,
		renterConsumptionLoading: false,
		renterConsumptions: [],
		ocInformation: undefined,
		ocLoading: false,
		changeKey: "",
	} as HomeState;
	locListener: any;
	occTableRef = React.createRef<OwnTable>();
	addOCDialogRef = React.createRef<Dialog>();
	editOCDialogRef = React.createRef<Dialog>();
	manageRenterConsumptionRef = React.createRef<Dialog>();

	componentDidMount() {
		this.locListener = RouterHistory.listen((location) => {
			this.loadHouse(window.location.hash.replace("#", ""));
		});
		this.loadHouse(window.location.hash.replace("#", ""));
	}

	componentWillUnmount() {
		this.locListener && this.locListener();
	}

	loadHouse(id: any, noLoading?: boolean, fromHOCC?: boolean, cb?: () => void) {
		if (id !== "-1" && id !== -1) {
			this.setState({ loading: noLoading ? false : fromHOCC ? false : true, hoccLoading: fromHOCC ? true : false }, () => {
				window.backend.getData(`/houses/${id}`, true).then((result) => {
					result.operatingCosts = result.operatingCosts?.map((oc: any) => {
						oc.dateFrom = dayjs(oc.dateFrom).format("DD.MM.YYYY");
						oc.dateTo = dayjs(oc.dateTo).format("DD.MM.YYYY");
						return oc;
					});

					this.setState({ house: result, loading: false }, cb);
				});
			});
		}
	}

	filterList(value: any) {
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			window.backend.getData(`/house/oc/${this.state.house.id}?search=${value}`, true).then((result) => {
				const house = this.state.house;
				result = result.map((oc: any) => {
					oc.dateFrom = dayjs(oc.dateFrom).format("DD.MM.YYYY");
					oc.dateTo = dayjs(oc.dateTo).format("DD.MM.YYYY");
					return oc;
				});
				house.operatingCosts = result;
				this.setState({ house });
			});
		}, 200);
	}

	newOCId?: number = undefined;
	createOC = (values: any, reset: any) => {
		let tmp = values.dateFrom.split(".");
		values.dateFrom = new Date(`${tmp[2]}-${tmp[1]}-${tmp[0]} 00:00:00`).getTime();

		tmp = values.dateTo.split(".");
		values.dateTo = new Date(`${tmp[2]}-${tmp[1]}-${tmp[0]} 00:00:00`).getTime();
		values.houseId = this.state.house?.id;
		window.backend.postData("/house/oc", values, true).then((data) => {
			if (!this.state.newOCConsumption) {
				this.addOCDialogRef.current?.close();

				this.loadHouse(this.state.house.id + "");
				reset();
			} else {
				this.newOCId = data.id;
				this.occRef.current?.setPage(3);
				this.loadHouseRenters();
				reset(true);
			}
		});
	};

	editOC = (values: any) => {
		this.setState({ ocLoading: true }, () => {
			this.editOCDialogRef.current?.toggle();
			const ocInformation: OcDialogInformation = {
				ocId: values[0],
				dateFrom: values[3],
				dateTo: values[4],
				price: values[5],
			};

			this.setState({ ocInformation: ocInformation, ocLoading: false });
		});
	};

	updateOC = (values: any) => {
		const ocId = this.state.ocInformation?.ocId;
		values.dateFrom = formatDate(values.dateFrom);
		values.dateTo = formatDate(values.dateTo);

		window.backend.putData(`/house/oc/${ocId}`, values, true).then(() => {
			this.editOCDialogRef.current?.close();
			this.loadHouse(this.state.house.id + "");
		});
	};

	loadOccs = () => {
		this.setState({ hoccLoading: true }, () => {
			window.backend.getData("/occ", true).then((occs) => {
				this.setState({ hoccLoading: false, occs: occs }, () => {
					let occTableRowRefs: any = {};
					const rowRefs = this.occTableRef.current?.getRowRefs();
					rowRefs?.forEach((ref) => {
						const values = ref.current?.getValues();
						if (values) {
							occTableRowRefs[values[0]] = ref;
						}
					});

					for (const hoccs of this.state.house.operatingCostsCategories) {
						setTimeout(() => {
							occTableRowRefs[hoccs.id]?.current?.setSelected(true);
						});
					}
				});
			});
		});
	};

	deleteOC = (values: any) => {
		window.backend.deleteData("/house/oc", { id: values[0] }, true).then(() => {
			this.loadHouse(this.state.house.id + "");
		});
	};

	addOcc = (values: any, reset: any) => {
		window.backend.postData("/occ", values, true).then(() => {
			this.loadOccs();
			reset();
		});
	};

	deleteOcc = (values: any) => {
		window.backend.deleteData("/occ", { id: values[0] }, true).then(() => {
			this.loadOccs();
		});
	};

	onSelectHocc = () => {
		const selected = this.occTableRef.current?.getSelected().map((oc) => {
			return { categoryId: oc[0], distributionKey: oc[2] };
		});
		this.setState({ hoccLoading: true }, () => {
			window.backend.putData("/house/hoccs", { id: this.state.house.id, hoccs: selected }, true).then(() => {
				this.loadHouse(this.state.house.id, false, true, () => {
					this.setState({ hoccLoading: false }, () => {
						this.occRef.current?.prevPage();
					});
				});
			});
		});
	};

	onCreationFormChange = (values: any) => {
		const categoryId = values.categoryId;

		const category: any = this.state.house.operatingCostsCategories.find((oc) => oc.id === categoryId);
		if (!category) return;
		if (category && category.distributionKey === "Verbrauch" && !this.state.newOCConsumption) {
			this.setState({ newOCConsumption: true });
		} else if (category && category.distributionKey !== "Verbrauch" && this.state.newOCConsumption) {
			this.setState({ newOCConsumption: false });
		}
	};

	loadHouseRenters = () => {
		this.setState({ rentersLoading: true }, () => {
			window.backend.getData(`/house/renter/${this.state.house.id}`, true).then((renters: User[]) => {
				this.setState({ renters: renters, rentersLoading: false });
			});
		});
	};

	saveRenterConsumptions = (update?: boolean) => {
		let renters: any = {};
		let refs = update ? this.renterUpdaterRefs : this.renterRefs;
		let totalConsumption = 0;
		for (const renter in refs) {
			if (renter === "totalRef") {
				totalConsumption = refs.totalRef.current?.getValue().value;
			} else {
				const value = refs[renter].current?.getValue();
				renters[renter] = value.value;
			}
		}

		window.backend
			.postData("/house/oc/consumptions", { consumptions: renters, oCId: this.newOCId, totalConsumption: totalConsumption }, true)
			.then(() => {
				this.addOCDialogRef.current?.close();
				this.occRef.current?.setPage(0);
				this.newOCId = undefined;
				this.loadHouse(this.state.house.id);
			});
	};

	openOCRenterConsumption = (oCId: number, totalConsumption: number) => {
		this.newOCId = oCId;
		this.manageRenterConsumptionRef.current?.open();
		this.setState({ renterConsumptionLoading: true }, () => {
			window.backend.getData(`/house/oc/consumptions/${oCId}`, true).then((consumptions: any[]) => {
				this.setState({ renterConsumptionLoading: false, renterConsumptions: consumptions, totalConsumption: totalConsumption });
			});
		});

		this.loadHouseRenters();
	};

	updateDistributionKey = (values: any, reset: any) => {
		const distributionKey = values.distributionKey;
		window.backend.putData(`/hocc/${this.changingHOCc}/${this.state.house.id}`, { distributionKey: distributionKey }, true).then(() => {
			this.setState({ changeKey: "" }, () => {
				reset();
				this.loadHouse(this.state.house.id, true, false, () => {
					this.loadOccs();
				});
			});
		});
	};

	renterUpdaterRefs: any = {};
	renterRefs: any = {};
	changingHOCc?: number;
	deletionOCCValues?: any;
	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		this.renterUpdaterRefs = { totalRef: React.createRef<Input>() };
		return (
			<BasePage title="Dashboard">
				<Dialog ref={this.manageRenterConsumptionRef} title="Mieterverbrauch" closeable>
					<div className="manage-renter-consumption">
						{(this.state.renterConsumptionLoading || this.state.rentersLoading) && <Spinner></Spinner>}
						{!this.state.renterConsumptionLoading && !this.state.rentersLoading && (
							<div className="renter-consumption">
								<div className="consumption-total">
									<div className="consumption-total-label">Gesamtverbrauch</div>
									<div className="renter-value">
										<Input
											ref={this.renterUpdaterRefs.totalRef}
											maxWidth="100px"
											name="consumption"
											type="number"
											defaultValue={this.state.totalConsumption}
											formnovalidate
											placeholder="Verbrauch"
										></Input>
									</div>
								</div>
								<div className="renter-consumption-content">
									{this.state.renters.map((renter, index) => {
										const ref = React.createRef<Input>();
										this.renterUpdaterRefs[renter.userId] = ref;
										const consumption = this.state.renterConsumptions.find(
											(consumption) => consumption.renterId === renter.userId
										);
										let defaultValue;
										if (consumption) defaultValue = consumption.consumption;
										return (
											<div className="renter-consumption-item" key={index}>
												<div className="renter-name">{renter.name}</div>
												<div className="renter-value">
													<Input
														ref={ref}
														maxWidth="100px"
														name="consumption"
														type="number"
														defaultValue={defaultValue}
														formnovalidate
														placeholder="Verbrauch"
													></Input>
												</div>
											</div>
										);
									})}
								</div>
								<div className="renter-consumption-footer">
									<Button type="success" text="Speichern" onClick={() => this.saveRenterConsumptions(true)}></Button>
								</div>
							</div>
						)}
					</div>
				</Dialog>
				<Dialog ref={this.editOCDialogRef} closeable title="Betriebskosten Bearbeiten">
					{this.state.ocLoading && <Spinner></Spinner>}
					{!this.state.ocLoading && this.state.ocInformation && (
						<Form
							onSubmit={this.updateOC}
							onChange={(values) => this.onCreationFormChange(values)}
							onReset={() => this.editOCDialogRef.current?.close()}
						>
							<Input name="dateFrom" maxWidth="50%" type="date" label="Gültig von" defaultValue={this.state.ocInformation.dateFrom} />
							<Input name="dateTo" maxWidth="50%" type="date" label="Gültig bis" defaultValue={this.state.ocInformation.dateTo} />

							<Input name="price" type="number" label="Kosten" defaultValue={this.state.ocInformation.price} />

							<Input name="" type="button" placeholder="Abbrechen" cType="CANCEL" />
							<Input name="" type="button" placeholder={"Speichern"} cType="SUBMIT" />
						</Form>
					)}
				</Dialog>
				<Dialog ref={this.addOCDialogRef} closeable title="Betriebskosten Anlegen">
					<SwipeContainer ref={this.occRef}>
						<SwipePage>
							<Form
								onSubmit={this.createOC}
								onChange={(values) => this.onCreationFormChange(values)}
								onReset={() => this.addOCDialogRef.current?.close()}
							>
								<Input
									name="categoryId"
									type="searchselect"
									label="Kategorie"
									options={
										this.state.house?.operatingCostsCategories?.map((oc) => {
											return { value: oc.id, label: oc.name };
										}) ?? []
									}
								/>
								<div className="hocc-button">
									<button
										onClick={(ev) => {
											ev.preventDefault();
											this.loadOccs();

											this.occRef.current?.nextPage();
										}}
									>
										Betriebskostenkategorien verwalten.
									</button>
								</div>
								<FormFiller></FormFiller>
								<Input name="dateFrom" maxWidth="50%" type="date" label="Gültig von" />
								<Input name="dateTo" maxWidth="50%" type="date" label="Gültig bis" />
								<Input name="price" type="number" label="Kosten" />

								{this.state.newOCConsumption && (
									<Input name="consumption" placeholder="Verbrauch" label="Gesamtverbrauch" type="number"></Input>
								)}

								<Input name="" type="button" placeholder="Abbrechen" cType="CANCEL" />
								<Input
									name=""
									type="button"
									placeholder={this.state.newOCConsumption ? "Mieterverbrauch eintragen" : "Anlegen"}
									cType="SUBMIT"
								/>
							</Form>
						</SwipePage>
						<SwipePage height="300px">
							<div className="hoccs-wrapper">
								<div className="hoccs-table">
									{this.state.hoccLoading && <Spinner></Spinner>}
									{!this.state.hoccLoading && (
										<OwnTable
											noDataText="Keine Daten"
											addButtonText="Anlegen"
											ref={this.occTableRef}
											propertyButtons={[
												{
													label: "Löschen",
													onClick: (values) => {
														this.deletionOCCValues = values;
														this.occRef.current?.setPage(5);
													},
												},
											]}
											selectType="MULTI"
											onAdd={() => {
												this.occRef.current?.nextPage();
											}}
										>
											<TableHeader>
												<HeaderCell minWidth={100} align="CENTER">
													Name
												</HeaderCell>
												<HeaderCell align="CENTER">Verteilschlüssel</HeaderCell>
											</TableHeader>
											<TableBody>
												{this.state.occs.map((occ, index) => {
													const hocc = this.state.house.operatingCostsCategories.find((hocc) => hocc.name === occ.name);
													const propertyButtons: PropertyButton[] = [];
													if (hocc) {
														propertyButtons.push({
															label: "Schlüssel ändern",
															onClick: () => {
																this.setState({ changeKey: hocc.distributionKey }, () => {
																	this.changingHOCc = hocc.categoryId;
																	this.occRef.current?.setPage(4);
																});
															},
														});
													}
													return (
														<TableRow propertyButtons={propertyButtons} key={index}>
															<Cell notVisible>{occ.id}</Cell>
															<Cell>{occ.name}</Cell>
															<Cell>{hocc ? hocc.distributionKey : occ.standardDistributionKey}</Cell>
														</TableRow>
													);
												})}
											</TableBody>
										</OwnTable>
									)}
								</div>

								<div className="hoccs-creation-footer">
									<Button
										text="Speichern"
										type="success"
										onClick={() => {
											this.onSelectHocc();
										}}
									></Button>
								</div>
							</div>
						</SwipePage>
						<SwipePage>
							<div className="hoccs-wrapper">
								<div className="hoccs-form">
									<Form
										onReset={() => {
											this.occRef.current?.prevPage();
										}}
										onSubmit={(values, reset) => {
											this.addOcc(values, reset);
										}}
									>
										<Input name="name" type="text" label="Name"></Input>
										<Input
											name="standardDistributionKey"
											type="searchselect"
											label="Verteilschlüssel"
											options={[
												{ label: "Pro Person", value: "Personen" },
												{ label: "Nach Verbrauch", value: "Verbrauch" },
												{ label: "Nach Fläche", value: "Fläche" },
											]}
										></Input>
										<Input name="" type="button" placeholder="Abbrechen" cType="CANCEL"></Input>
										<Input name="" type="button" placeholder="Anlegen" cType="SUBMIT"></Input>
									</Form>
								</div>
							</div>
						</SwipePage>
						<SwipePage>
							<div className="renter-consumption">
								<div className="renter-consumption-title">Mieterverbrauch</div>
								<div className="renter-consumption-content">
									{this.state.renters.map((renter, index) => {
										const ref = React.createRef<Input>();
										this.renterRefs[renter.userId] = ref;
										return (
											<div className="renter-consumption-item" key={index}>
												<div className="renter-name">{renter.name}</div>
												<div className="renter-value">
													<Input
														ref={ref}
														maxWidth="100px"
														name="consumption"
														type="number"
														formnovalidate
														placeholder="Verbrauch"
													></Input>
												</div>
											</div>
										);
									})}
								</div>
								<div className="renter-consumption-footer">
									<Button type="success" text="Speichern" onClick={() => this.saveRenterConsumptions(false)}></Button>
								</div>
							</div>
						</SwipePage>
						<SwipePage>
							<div className="hoccs-wrapper">
								<div className="hoccs-form">
									{this.state.changeKey !== "" && (
										<Form
											onReset={() => {
												this.setState({ changeKey: "" }, () => {
													this.changingHOCc = undefined;
													this.occRef.current?.setPage(1);
												});
											}}
											title="Verteilschlüssel"
											onSubmit={(values, reset) => {
												this.updateDistributionKey(values, reset);
											}}
										>
											<Input
												name="distributionKey"
												type="searchselect"
												label="Verteilschlüssel"
												defaultValue={this.state.changeKey}
												options={[
													{ label: "Pro Person", value: "Personen" },
													{ label: "Nach Verbrauch", value: "Verbrauch" },
													{ label: "Nach Fläche", value: "Fläche" },
												]}
											></Input>
											<Input name="" type="button" cType="CANCEL"></Input>
											<Input name="" type="button" cType="SUBMIT"></Input>
										</Form>
									)}
								</div>
							</div>
						</SwipePage>
						<SwipePage>
							<div className="occ-deletion">
								<div className="occ-content">
									Möchten sie die Betriebskategorie wirklich löschen? Wenn sie die Betriebskostenkategorie löschen, betrifft dies
									alle Ihre Häuser! Alternativ können sie auch die Betriebskategorie für einzelne Häuser ausblenden, indem sie den
									Haken in der Tabelle entfernen.
								</div>
								<div className="occ-footer">
									<Button
										type="success"
										text="Abbrechen"
										onClick={() => {
											this.deletionOCCValues = undefined;
											this.occRef.current?.setPage(1);
										}}
									></Button>
									<Button
										type="error"
										text="Löschen"
										onClick={() => {
											this.deleteOcc(this.deletionOCCValues);
											this.deletionOCCValues = undefined;
											this.loadOccs();
											this.occRef.current?.setPage(1);
										}}
									></Button>
								</div>
							</div>
						</SwipePage>
					</SwipeContainer>
				</Dialog>
				<div className="home">
					<div className="home-metadata">
						<div className="home-metadata-house">
							<div className="home-infos">
								<div className="home-icon">
									<Icon>apartment</Icon>
								</div>
								<div className="home-data">
									<p id="house-name">{this.state.house.name}</p>
									<p>{`${this.state.house.street} ${this.state.house.houseNumber}`}</p>
									<p>{`${this.state.house.plz} ${this.state.house.city}`}</p>
								</div>
							</div>
							<div className="home-infos">
								<div className="home-icon">
									<Icon>person</Icon>
								</div>
								<div className="home-data">
									<p id="house-name">{this.state.house.user?.name}</p>
									<p>{this.state.house.user?.email}</p>
									<p>{this.state.house.user?.telephone}</p>
									<p>{dayjs(this.state.house.user?.birthdate).format("DD.MM.YYYY")}</p>
								</div>
							</div>
						</div>
						<div className="home-metadata-general">
							<div className="home-infos">
								<div className="home-icon">
									<Icon>info</Icon>
								</div>
								<div className="home-data">
									<p>
										<span>Wohnungen:</span> {this.state.house.infos?.apartments}
									</p>
									<p>
										<span>Zimmer:</span> {this.state.house.infos?.rooms}
									</p>
									<p>
										<span>Mieteranzahl:</span> {this.state.house.infos?.renters}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="home-graphs" id="home-graph">
						<div className="img-wrapper">
							<img src="/DUBFullLogo.png" alt="Logo"></img>
						</div>
					</div>
					<div className="home-occs">
						<div className="home-occs-wrapper">
							<OwnTable
								noDataText="Keine Daten"
								addButtonText="Anlegen"
								sortable
								propertyButtons={[
									{
										label: "Löschen",
										onClick: (values) => {
											this.deleteOC(values);
										},
									},
									{
										label: "Bearbeiten",
										onClick: (values) => {
											this.editOC(values);
										},
									},
								]}
								onAdd={() => this.addOCDialogRef.current?.open()}
								searchable
							>
								<TableHeader>
									<HeaderCell minWidth={55} maxWidth="500px" align="CENTER">
										Kategorie
									</HeaderCell>
									<HeaderCell minWidth={75} maxWidth="200px" align="CENTER">
										Gültig von
									</HeaderCell>
									<HeaderCell minWidth={75} maxWidth="200px" align="CENTER">
										Gültig bis
									</HeaderCell>
									<HeaderCell minWidth={50} maxWidth="200px" align="CENTER">
										Kosten
									</HeaderCell>
								</TableHeader>
								<TableBody>
									{this.state.house?.operatingCosts?.map((oc, index) => (
										<TableRow
											key={index}
											propertyButtons={
												oc.distributionKey === "Verbrauch"
													? [
															{
																onClick: (values) => this.openOCRenterConsumption(oc.id ?? -1, values[1]),
																label: "Mieterverbrauch",
															},
													  ]
													: []
											}
										>
											<Cell notVisible>{oc.id}</Cell>
											<Cell notVisible>{oc.consumption ?? 0}</Cell>
											<Cell>{oc.name}</Cell>
											<Cell>{oc.dateFrom}</Cell>
											<Cell>{oc.dateTo}</Cell>
											<Cell>{oc.price}</Cell>
										</TableRow>
									)) ?? []}
								</TableBody>
							</OwnTable>
						</div>
					</div>
				</div>
			</BasePage>
		);
	}
}
