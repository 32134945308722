import React, { Component } from "react";
import { detect } from "detect-browser";

export interface BasePageProps {
	title: string;
}

export interface BasePageState {
	isSafari: boolean;
}

export default class BasePage extends Component<BasePageProps, BasePageState> {

	state = {
		isSafari: false
	}

	componentDidMount = () => {
		const browser = detect();
		if(browser?.name === "safari") {
			this.setState({isSafari: true});
		}
	};

	getClasses = () => {
		const classes = ["basepage"];
		if(this.state.isSafari) classes.push("safari");
		return classes.join(" ");
	};

	render() {
		return (
			<div className={this.getClasses()}>
				<div className="basepage-title">{this.props.title}</div>
				<div className="basepage-content">{this.props.children}</div>
			</div>
		);
	}
}
