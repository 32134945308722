import React from "react";
import "./App.css";
import "@klumpp/tools/dist/klumpp-tools.css";
import { Auth, AuthCallback, BackendInterface, Cookie, CookieBanner, Logout, RouterHistory, ToastContainer } from "@klumpp/tools";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import "../styles/customize/dub.scss";

interface IAppState {
	auth: Auth;
}
class App extends React.Component<any, IAppState> {
	constructor(props: any) {
		super(props);
		window.auth = new Auth(
			this.props.history,
			window.env.REACT_APP_AUTH0_DOMAIN as string,
			window.env.REACT_APP_AUTH0_CLIENT_ID as string,
			window.env.REACT_APP_AUTH0_CALLBACK_URL as string,
			window.env.REACT_APP_AUTH0_AUDIENCE as string
		);
		window.routerHistory = this.props.history;
		window.backend = new BackendInterface(window.auth, RouterHistory, window.env.REACT_APP_API_URL as string, {
			history: RouterHistory as any,
			errorPath: "/error",
		});
		window.cookies = new Cookie();
		this.state = {
			auth: window.auth,
		};
	}

	changeTitle = (currentPageName: string) => {
		let title = document.querySelector("title");
		if (title) {
			title.innerHTML = currentPageName;
		}
	};

	componentDidMount() {}

	render() {
		return (
			<div className="app">
				<ToastContainer />
				<CookieBanner cookieClass={window.cookies} history={RouterHistory}>
					Wir verwenden Cookies, um Inhalte auf unserer Webseite zu personalisieren. Wenn sie dies nicht wollen klicken sie bitte auf
					"Ablehnen".
				</CookieBanner>

				<Switch>
					<Route exact path="/" render={() => <Redirect to="/dashboard"></Redirect>}></Route>
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/callback" render={(props) => <AuthCallback auth={this.state.auth} />} />
					<Route path="/login" render={(props) => <Redirect to="/" />} />
					<Route
						path="/logout"
						render={(props) => <Logout logoutUrl={window.env.REACT_APP_LOGOUT_URL} to="/" history={RouterHistory} auth={window.auth} />}
					/>
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
