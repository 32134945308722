import { Dialog } from "@klumpp/tools";
import React, { Component, RefObject } from "react";
import "./ImpressumDialog.scss";

export interface ImpressumDialogProps {
	dlgRef: RefObject<Dialog>;
}

export interface ImpressumDialogState {}

export default class ImpressumDialog extends Component<ImpressumDialogProps, ImpressumDialogState> {
	render() {
		return (
			<Dialog ref={this.props.dlgRef} title="Impressum" closeable>
				<div className="impressum">
					<p>Informationspflicht laut § 5 TMG.</p>
					<br />
					<p>DUB - Dashboard Utility Billing</p>
					<p className="fine">Coblitzalle 1-9,</p>
					<p className="fine">68919 Mannheim,</p>
					<p className="fine">Deutschland</p>
					<br />
					<p>
						<span>Tel.:</span> 12344
					</p>
					<p>
						<span>Fax:</span> 12344
					</p>
					<p>
						<span>E-Mail:</span> test@test.com
					</p>
					<br />
					<p>Aufsichtsbehörde</p>
					<p className="fine">DHBW Mannheim</p>
					<p className="fine">Webseite der Aufsichtsbehörde</p>
					<p className="fine">https://dub.blickleklumppwiens.de</p>
					<br />
					<p>
						<span>Berufsbezeichnung:</span> Webdesigner, Grafiker
					</p>
					<br />
					<h3>EU-Streitschlichtung</h3>
					<p>
						Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
						Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden an die Online
						Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=321240874 zu richten. Die dafür
						notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
					</p>
					<br />
					<p>
						Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
						Verbraucherschlichtungsstelle teilzunehmen.
					</p>
					<br />
					<h3>Haftung für Inhalte dieser Website</h3>
					<p>
						Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen.
						Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen,
						nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
						Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10
						sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu
						forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
					</p>
					<br />
					<p>
						Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen
						Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach
						den §§ 8 bis 10 unberührt.
					</p>
					<br />
					<p>
						Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die
						rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
					</p>
					<br />
					<h3>Haftung für Links auf dieser Website</h3>
					<p>
						Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte
						Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche
						Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
						bekannt werden.
					</p>
					<br />
					<p>
						Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten
						im Impressum.
					</p>
					<br />
					<h3>Urheberrechtshinweis</h3>
					<p>
						Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte
						fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen
						Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite
						rechtlich verfolgen.
					</p>
					<br />
					<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
					<br />
					<h3>Bildernachweis</h3>
					<p>
						Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt. Die Bilderrechte liegen bei den folgenden
						Fotografen und Unternehmen: Fotograf Mustermann
					</p>
				</div>
			</Dialog>
		);
	}
}
