import { Dialog, Input, RouterHistory, Spinner, Form, OwnTable, TableHeader, HeaderCell, TableBody, TableRow, Cell } from "@klumpp/tools";
import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import "./Apartments.scss";
import { Apartment, Room } from "../../../types/ObjectTypes";

export interface ApartmentsProps {}

interface ApartmentInformation {
	name: string;
	etage: number;
	size: number;
	coldRent: number;
	additionalCosts: number;
}

export interface ApartmentsState {
	houseId?: string;
	loading: boolean;
	apartmentLoading: boolean;
	apartments: Apartment[];
	currentApartment: any;
	currentPage: "LIST" | "CREATION";
	rooms: Room[];
	apartmentInformation?: ApartmentInformation;
}

export default class Apartments extends Component<ApartmentsProps, ApartmentsState> {
	state = {
		houseId: "",
		loading: true,
		apartmentLoading: false,
		apartments: [],
		currentPage: "LIST",
		currentApartment: "",
		rooms: [],
		apartmentInformation: undefined,
	} as ApartmentsState;
	locListener: any;
	addDialogRef = React.createRef<Dialog>();
	editDialogRef = React.createRef<Dialog>();
	roomManager = React.createRef<Dialog>();

	testData = [{ c1: 1, c2: 2, c3: 3, c4: 4 }];

	componentDidMount() {
		this.locListener = RouterHistory.listen((location) => {
			this.setState({ houseId: location.hash.replace("#", "") }, () => {
				this.loadApartments();
			});
		});
		this.setState({ houseId: window.location.hash.replace("#", "") }, () => {
			this.loadApartments();
		});
	}

	loadApartments = (loading?: boolean) => {
		this.setState({ loading: loading === false ? false : true }, () => {
			window.backend.getData(`/apartments/${this.state.houseId}`, true).then((apartments) => {
				this.setState({ loading: false, apartments });
			});
		});
	};

	addApartment = (values: any) => {
		this.addDialogRef.current?.close();
		values.houseId = this.state.houseId;
		window.backend.postData("/apartments", values, true).then(() => {
			this.loadApartments();
		});
	};

	deleteApartment = (values: any) => {
		const apartmentId = values[0];
		window.backend.deleteData(`/apartments/${apartmentId}`, values, true).then(() => {
			this.loadApartments();
		});
	};

	editApartment = (values: any) => {
		this.setState({ apartmentLoading: true }, () => {
			this.editDialogRef.current?.toggle();
			const apartmentInformation: ApartmentInformation = {
				name: values[1],
				etage: values[2],
				size: values[3],
				coldRent: values[4],
				additionalCosts: values[5],
			};

			this.setState({ apartmentInformation: apartmentInformation, currentApartment: values[0], apartmentLoading: false });
		});
	};

	updateApartment = (values: any) => {
		this.setState({ apartmentLoading: true }, () => {
			window.backend.putData(`/apartments/${this.state.currentApartment}`, values, true).then(() => {
				this.loadApartments();
			});
		});
	};

	componentWillUnmount() {
		this.locListener && this.locListener();
	}

	loadRooms = (cb?: () => void) => {
		window.backend.getData(`/rooms/${this.state.currentApartment}`, true).then((rooms) => {
			this.setState({ rooms: rooms }, cb);
		});
	};

	showApartmentRooms = (values: any) => {
		const apartmentId = values[0];
		this.setState({ currentApartment: apartmentId, currentPage: "LIST" }, () => {
			this.loadRooms(() => {
				this.roomManager.current?.open();
			});
		});
	};

	createRoom = (values: any, reset: any) => {
		values.apartmentId = this.state.currentApartment;
		window.backend.postData("/rooms", values, true).then(() => {
			reset();
			this.loadRooms();
			this.loadApartments(false);
			this.setState({ currentPage: "LIST" });
		});
	};

	deleteRoom = (values: any) => {
		const roomId = values[0];
		window.backend.deleteData("/rooms", { id: roomId }, true).then(() => {
			this.loadRooms();
			this.loadApartments(false);
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		return (
			<BasePage title="Wohnungen">
				<Dialog ref={this.editDialogRef} title="Wohnung Bearbeiten" closeable>
					{this.state.apartmentLoading && <Spinner></Spinner>}
					{!this.state.apartmentLoading && this.state.apartmentInformation && (
						<Form onReset={() => this.editDialogRef.current?.close()} onSubmit={this.updateApartment}>
							<Input name="name" label="Name" type="text" defaultValue={this.state.apartmentInformation.name}></Input>
							<Input
								name="etage"
								maxWidth="50%"
								label="Etage"
								type="number"
								formnovalidate
								defaultValue={this.state.apartmentInformation.etage}
							></Input>
							<Input
								name="size"
								maxWidth="50%"
								label="Fläche"
								type="number"
								defaultValue={this.state.apartmentInformation.size}
							></Input>
							<Input
								name="coldRent"
								maxWidth="50%"
								label="Kaltmiete"
								type="number"
								defaultValue={this.state.apartmentInformation.coldRent}
							></Input>
							<Input
								name="additionalCosts"
								maxWidth="50%"
								label="Warmmiete"
								type="number"
								defaultValue={this.state.apartmentInformation.additionalCosts}
							></Input>
							<Input name="" type="button" cType="CANCEL" placeholder="Abbrechen"></Input>
							<Input name="" type="button" cType="SUBMIT" placeholder="Aktualisieren"></Input>
						</Form>
					)}
				</Dialog>
				<Dialog ref={this.addDialogRef} title="Wohnung Anlegen" closeable>
					<Form onReset={() => this.addDialogRef.current?.close()} onSubmit={this.addApartment}>
						<Input name="name" label="Name" type="text"></Input>
						<Input name="etage" maxWidth="50%" label="Etage" type="number" formnovalidate></Input>
						<Input name="size" maxWidth="50%" label="Fläche" type="number"></Input>
						<Input name="coldRent" maxWidth="50%" label="Kaltmiete" type="number"></Input>
						<Input name="additionalCosts" maxWidth="50%" label="Warmmiete" type="number"></Input>
						<Input name="" type="button" cType="CANCEL" placeholder="Abbrechen"></Input>
						<Input name="" type="button" cType="SUBMIT" placeholder="Anlegen"></Input>
					</Form>
				</Dialog>
				<Dialog closeable ref={this.roomManager} title="Zimmer">
					<div className={this.state.currentPage === "CREATION" ? "room-manager creation" : "room-manager"}>
						<div className="room-list">
							<OwnTable
								noDataText="Keine Daten"
								addButtonText="Anlegen"
								propertyButtons={[
									{
										label: "Löschen",
										onClick: (values) => {
											this.deleteRoom(values);
										},
									},
								]}
								onAdd={() => this.setState({ currentPage: "CREATION" })}
							>
								<TableHeader>
									<HeaderCell minWidth={50} maxWidth="300px" align="CENTER">
										Name
									</HeaderCell>
									<HeaderCell minWidth={50} maxWidth="200px" align="CENTER">
										Fläche
									</HeaderCell>
								</TableHeader>
								<TableBody>
									{this.state.rooms?.map((room) => (
										<TableRow>
											<Cell notVisible>{room.id}</Cell>
											<Cell>{room.name}</Cell>
											<Cell>{room.size}</Cell>
										</TableRow>
									)) ?? []}
								</TableBody>
							</OwnTable>
						</div>
						<div className="room-creation">
							<Form
								onSubmit={this.createRoom}
								onReset={() => {
									this.setState({ currentPage: "LIST" });
								}}
							>
								<Input name="name" type="text" label="Name"></Input>
								<Input name="size" type="number" label="Fläche"></Input>

								<Input name="" type="button" placeholder="Abbrechen" cType="CANCEL"></Input>
								<Input name="" type="button" placeholder="Anlegen" cType="SUBMIT"></Input>
							</Form>
						</div>
					</div>
				</Dialog>
				<div className="content">
					<OwnTable
						noDataText="Keine Daten"
						addButtonText="Anlegen"
						searchable
						propertyButtons={[
							{
								label: "Zimmer Verwalten",
								onClick: (values) => {
									this.showApartmentRooms(values);
								},
							},
							{
								label: "Bearbeiten",
								onClick: (values) => {
									this.editApartment(values);
								},
							},
							{
								label: "Löschen",
								onClick: (values) => {
									this.deleteApartment(values);
								},
							},
						]}
						onAdd={() => this.addDialogRef.current?.open()}
					>
						<TableHeader>
							<HeaderCell minWidth={50} maxWidth="300px" align="CENTER">
								Name
							</HeaderCell>
							<HeaderCell minWidth={50} maxWidth="200px" align="CENTER">
								Etage
							</HeaderCell>
							<HeaderCell minWidth={50} maxWidth="200px" align="CENTER">
								Fläche
							</HeaderCell>
							<HeaderCell minWidth={70} align="CENTER">
								Kaltmiete
							</HeaderCell>
							<HeaderCell minWidth={70} align="CENTER">
								Warmmiete
							</HeaderCell>
							<HeaderCell minWidth={70} align="CENTER">
								Zimmer
							</HeaderCell>
						</TableHeader>
						<TableBody>
							{this.state.apartments?.map((apartment, index) => (
								<TableRow key={index}>
									<Cell notVisible>{apartment.id}</Cell>
									<Cell>{apartment.name}</Cell>
									<Cell>{apartment.etage}</Cell>
									<Cell>{apartment.size}</Cell>
									<Cell>{apartment.coldRent}</Cell>
									<Cell>{apartment.additionalCosts}</Cell>
									<Cell>{apartment.roomCount}</Cell>
								</TableRow>
							)) ?? []}
						</TableBody>
					</OwnTable>
				</div>
			</BasePage>
		);
	}
}
