import { Form, Input, RouterHistory, showNotification, Spinner } from "@klumpp/tools";
import { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import SettingsItem from "../settings/SettingsItem";
import "./Profile.scss";
import dayjs from "dayjs";
import { formatDate } from "../../../utils/Utils";

export interface ProfileProps {}
type Status = "NEUTRAL" | "VALID" | "INVALID";
export interface ProfileState {
	minimumLength: Status;
	minimumUpperCase: Status;
	minimumLowserCase: Status;
	minimumSpecial: Status;
	minimumNumber: Status;
	samePassword: Status;
	user: any;
	loadingPersonalData: boolean;
	loadingLandlordData: boolean;
	landlordData: any;
	personalData: any;
}

export default class Profile extends Component<ProfileProps, ProfileState> {
	state = {
		user: {},
		samePassword: "NEUTRAL",
		minimumNumber: "NEUTRAL",
		minimumLength: "NEUTRAL",
		minimumUpperCase: "NEUTRAL",
		minimumLowserCase: "NEUTRAL",
		minimumSpecial: "NEUTRAL",
		loadingPersonalData: true,
		loadingLandlordData: true,
		landlordData: {},
		personalData: {},
	} as ProfileState;
	onSubmitPassword = async (values: any, reset: any) => {
		if (
			this.state.samePassword === "VALID" &&
			this.state.minimumLength === "VALID" &&
			this.state.minimumLowserCase === "VALID" &&
			this.state.minimumNumber === "VALID" &&
			this.state.minimumSpecial === "VALID" &&
			this.state.minimumUpperCase === "VALID"
		) {
			if (values.oldPassword !== "" && values.newPassword !== "" && values.newWdhPassword !== "") {
				if (values.oldPassword !== values.newPassword) {
					await window.backend.putData("/user/password", values, true).then(() => {
						RouterHistory.replace("/");
						reset();
					});
				} else {
					showNotification({
						msg: "Ihr neues Passwort sollte nicht das alte Passwort sein.",
						type: "E",
					});
				}
			} else {
				showNotification({ msg: "Bitte füllen sie alle Felder aus.", type: "E" });
			}
		} else {
			showNotification({
				msg: "Bitte prüfen sie alle Passwort Vorausetzungen.",
				type: "E",
			});
		}
	};

	componentDidMount() {
		this.loadLandlordData();
		this.loadPersonalData();
		window.auth.getUser().then((user) => {
			this.setState({ user: user });
		});
	}

	onPasswordChange = (values: any) => {
		const newPassword = values.newPassword;
		const oldPassword = values.oldPassword;
		const wdhPassword = values.newWdhPassword;

		if (!newPassword && !oldPassword && !wdhPassword) {
			this.setState({
				minimumLength: "NEUTRAL",
				minimumSpecial: "NEUTRAL",
				minimumUpperCase: "NEUTRAL",
				minimumLowserCase: "NEUTRAL",
				minimumNumber: "NEUTRAL",
				samePassword: "NEUTRAL",
			});
			return;
		}

		if (newPassword !== "") {
			if (newPassword.length < 8) {
				this.setState({ minimumLength: "INVALID" });
			} else {
				this.setState({ minimumLength: "VALID" });
			}

			if (newPassword.match(/[a-z]/)) {
				this.setState({ minimumLowserCase: "VALID" });
			} else {
				this.setState({ minimumLowserCase: "INVALID" });
			}

			if (newPassword.match(/[A-Z]/)) {
				this.setState({ minimumUpperCase: "VALID" });
			} else {
				this.setState({ minimumUpperCase: "INVALID" });
			}

			if (newPassword.match(/[0-9]/)) {
				this.setState({ minimumNumber: "VALID" });
			} else {
				this.setState({ minimumNumber: "INVALID" });
			}
			//[$&+,:;=?@#|'<>.-^*()%!]
			if (newPassword.match(/[$&+,:;=?@#|'<>.\-^*()%!]/)) {
				this.setState({ minimumSpecial: "VALID" });
			} else {
				this.setState({ minimumSpecial: "INVALID" });
			}
		}

		if (newPassword !== "" && wdhPassword !== "") {
			if (newPassword === wdhPassword) {
				this.setState({ samePassword: "VALID" });
			} else {
				this.setState({ samePassword: "INVALID" });
			}
		}
	};

	loadPersonalData = () => {
		this.setState({ loadingPersonalData: true }, () => {
			window.backend.getData("/landlord/personal", true).then((personalData) => {
				this.setState({ personalData: personalData, loadingPersonalData: false });
			});
		});
	};

	loadLandlordData = () => {
		this.setState({ loadingLandlordData: true }, () => {
			window.backend.getData("/landlord", true).then((landlordData) => {
				this.setState({ landlordData: landlordData, loadingLandlordData: false });
			});
		});
	};

	updatePersonalData = (values: any, reset: any) => {
		values.birthdate = formatDate(values.birthdate);
		window.backend.putData("/landlord/personal", values, true).then(() => this.loadPersonalData());
	};

	updateLandlordData = (values: any, reset: any) => {
		window.backend.putData("/landlord", values, true).then(() => this.loadLandlordData());
	};

	render() {
		return (
			<BasePage title="Profil">
				<div className="profile">
					<SettingsItem title="Passwort ändern" noFooter>
						<div className="personal-user">
							<div className="change-password-fields">
								<Form onChange={this.onPasswordChange} onSubmit={this.onSubmitPassword}>
									<Input type="password" name="oldPassword" formnovalidate label="Altes Passwort"></Input>
									<Input type="password" name="newPassword" formnovalidate label="Neues Passwort"></Input>
									<Input type="password" name="newWdhPassword" formnovalidate label="Wiederholung"></Input>
									<Input type="button" cType="CANCEL" name="" placeholder="Abbrechen"></Input>
									<Input type="button" cType="SUBMIT" name="" placeholder="Speichern"></Input>
								</Form>
							</div>
							<div className="change-password-validations">
								<div className={`change-password-validations-item ${this.state.minimumLength}`}>Mindestens 8 Zeichen.</div>
								<div className={`change-password-validations-item ${this.state.minimumNumber}`}>Mindestens eine Zahl.</div>
								<div className={`change-password-validations-item ${this.state.minimumLowserCase}`}>
									Mindestens ein Kleinbuchstabe.
								</div>
								<div className={`change-password-validations-item ${this.state.minimumUpperCase}`}>Mindestens ein Großbuchstabe.</div>
								<div className={`change-password-validations-item ${this.state.minimumSpecial}`}>Mindestens ein Sonderzeichen.</div>
								<div className={`change-password-validations-item ${this.state.samePassword}`}>
									Das Passwort und das wiederholte Passwort müssen übereinstimmen.
								</div>
							</div>
						</div>
					</SettingsItem>
					<SettingsItem title="Persönliche Daten" noFooter>
						{this.state.loadingPersonalData && <Spinner></Spinner>}
						{!this.state.loadingPersonalData && (
							<Form onSubmit={this.updatePersonalData} onReset={this.loadPersonalData}>
								<Input name="name" type="text" label="Name" placeholder="Name" defaultValue={this.state.personalData.name}></Input>
								<Input
									name="birthdate"
									type="date"
									label="Geburtsdatum"
									placeholder="Geburtsdatum"
									defaultValue={dayjs(this.state.personalData.birthdate).format("DD.MM.YYYY")}
								></Input>
								<Input
									name="telephone"
									type="tel"
									label="Telefonnummer"
									placeholder="Telefonnummer"
									defaultValue={this.state.personalData.telephone}
								></Input>

								<Input type="button" cType="CANCEL" name="" placeholder="Abbrechen"></Input>
								<Input type="button" cType="SUBMIT" name="" placeholder="Speichern"></Input>
							</Form>
						)}
					</SettingsItem>
					<SettingsItem title="Vermieter Angaben" noFooter>
						{this.state.loadingLandlordData && <Spinner></Spinner>}
						{!this.state.loadingLandlordData && (
							<Form onSubmit={this.updateLandlordData} onReset={this.loadLandlordData}>
								<Input
									name="landlordStreet"
									type="text"
									label="Straße"
									placeholder="Straße und Hausnummer"
									defaultValue={this.state.landlordData.landlordStreet}
								></Input>
								<Input
									name="landlordCity"
									type="text"
									label="Stadt"
									placeholder="Stadt und PLZ"
									defaultValue={this.state.landlordData.landlordCity}
								></Input>
								<Input
									name="bank"
									type="text"
									label="Bank Name"
									placeholder="Name der Bank"
									defaultValue={this.state.landlordData.bank}
								></Input>
								<Input
									name="ibanBic"
									type="text"
									label="IBAN/BIC"
									placeholder="IBAN / BIC"
									defaultValue={this.state.landlordData.ibanBic}
								></Input>
								<Input
									name="owner"
									type="text"
									label="Inhaber"
									placeholder="Kontoinhaber"
									defaultValue={this.state.landlordData.owner}
								></Input>

								<Input type="button" cType="CANCEL" name="" placeholder="Abbrechen"></Input>
								<Input type="button" cType="SUBMIT" name="" placeholder="Speichern"></Input>
							</Form>
						)}
					</SettingsItem>
				</div>
			</BasePage>
		);
	}
}
