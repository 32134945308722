import {
	Button,
	CustomVerticalNavigationItem,
	Dialog,
	Form,
	IconButton,
	Input,
	Popover,
	RouterHistory,
	Spinner,
	VerticalNavigation,
	VerticalNavigationItem,
} from "@klumpp/tools";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Apartments from "../pages/apartments/Apartments";
import Home from "../pages/home/Home";
import Renter from "../pages/renter/Renter";
import "./Dashboard.scss";
import Settings from "../pages/settings/Settings";
import ImpressumDialog from "../elements/dialogs/ImpressumDialog";
import BasePage from "./BasePage";
import Profile from "../pages/profile/Profile";

export interface DashboardProps {}

interface User {
	picture: string;
	email: string;
	name: string;
}

interface House {
	value: number;
	label: string;
}

interface HouseInformation {
	name: string;
	city: string;
	zip: string;
	street: string;
	housenumber: string;
}

export interface DashboardState {
	user: User;
	userPopupOpen: boolean;
	houses: House[];
	selectedHouse: number;
	loading: boolean;
	houseLoading: boolean;
	houseSettingsOpen: boolean;
	houseInformation?: HouseInformation;
	navOpen: boolean;
}

export default class Dashboard extends Component<DashboardProps, DashboardState> {
	state = {
		houseSettingsOpen: false,
		loading: true,
		user: { picture: "", email: "", name: "" },
		userPopupOpen: false,
		houses: [],
		selectedHouse: -1,
		houseInformation: undefined,
		navOpen: false,
		houseLoading: false,
	} as DashboardState;

	impressumRef = React.createRef<Dialog>();
	userPopover = React.createRef<Popover>();
	addHouseDialogRef = React.createRef<Dialog>();
	editHouseDialogRef = React.createRef<Dialog>();
	houseInputRef = React.createRef<Input>();
	locListener: any;
	deleteRef = React.createRef<Dialog>();
	housePopover = React.createRef<Popover>();
	userNavPopover = React.createRef<Popover>();

	componentDidMount() {
		window.auth.getUser().then((user: any) => {
			if (!user) {
				window.auth.loginWithRedirect(window.location.href);
			}

			if (window.location.pathname === "/dashboard") {
				RouterHistory.replace("/dashboard/home");
			}

			this.setState({ user: user }, () => {
				this.getHouses();
				this.locListener = RouterHistory.listen((location) => {
					if (!window.location.hash || window.location.hash === "" || window.location.hash === "#") {
						RouterHistory.replace(`#${this.houseInputRef.current?.getValue().value}`);
					} else {
						const id = window.location.hash.replace("#", "");
						this.setState({ selectedHouse: Number(id) });
					}
				});
			});
		});
	}

	componentWillUnmount() {
		this.locListener && this.locListener();
	}

	getHouses = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/houses", true).then((houses) => {
				const values = houses.map((house: any) => {
					return { value: house.id, label: house.name };
				});

				let newSelected = this.state.selectedHouse;
				if (values.length !== 0) {
					if (newSelected === -1) {
						newSelected = values[0].value;
					} else {
						const item = values.find((val: any) => val.value === newSelected);
						if (!item) {
							newSelected = values[0].value;
						}
					}
				} else {
					newSelected = -1;
				}

				this.setState(
					{
						loading: false,
						houses: values,
						selectedHouse: newSelected,
					},
					() => {
						RouterHistory.replace(`#${this.houseInputRef.current?.getValue().value}`);
					}
				);
			});
		});
	};

	addHouse = (values: any, reset: any) => {
		//Add House
		window.backend.postData("/houses", values, true).then(() => {
			reset();
			this.getHouses();
		});
	};

	editHouse = () => {
		this.setState({ houseSettingsOpen: false, houseLoading: true }, () => {
			this.editHouseDialogRef.current?.toggle();
			window.backend.getData(`/houses/${this.state.selectedHouse}`, true).then((house) => {
				const houseInformation: HouseInformation = {
					city: house.city,
					housenumber: house.houseNumber,
					name: house.name,
					street: house.street,
					zip: house.plz,
				};
				this.setState({ houseInformation: houseInformation, houseLoading: false });
			});
		});
	};

	updateHouse = (values: any, reset: any) => {
		//Update House
		this.setState({ houseLoading: true }, () => {
			window.backend.postData(`/house/update/${this.state.selectedHouse}`, values, true).then(() => {
				reset();
				this.getHouses();
			});
		});
	};

	houseChanged = () => {
		const value = this.houseInputRef.current?.getValue();
		if (value?.value !== "") {
			window.location.hash = value?.value;
		}
	};
	deleteHouse = () => {
		this.deleteRef.current?.toggle();
		const id = window.location.hash.replace("#", "");
		window.backend.deleteData("/houses", { id: id }, true).then(() => {
			this.getHouses();
		});
	};

	closeNav = () => {
		if (this.state.navOpen) {
			this.setState({ navOpen: false });
		}
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		return (
			<div className={["dashboard", this.state.navOpen ? "nav-open" : ""].join(" ")}>
				<ImpressumDialog dlgRef={this.impressumRef}></ImpressumDialog>
				<Dialog ref={this.editHouseDialogRef} closeable title="Haus Bearbeiten">
					{this.state.houseLoading && <Spinner></Spinner>}
					{!this.state.houseLoading && this.state.houseInformation && (
						<Form onSubmit={this.updateHouse} onReset={this.editHouseDialogRef.current?.close}>
							<Input
								placeholder="Bezeichnung des Hauses"
								label="Name"
								name="name"
								type="text"
								defaultValue={this.state.houseInformation?.name}
							></Input>
							<Input
								maxWidth="65%"
								placeholder="Ort"
								label="Stadt"
								name="city"
								type="text"
								defaultValue={this.state.houseInformation?.city}
							></Input>
							<Input
								maxWidth="35%"
								placeholder="Postleitzahl"
								label="PLZ"
								name="plz"
								type="text"
								defaultValue={this.state.houseInformation?.zip}
							></Input>
							<Input
								maxWidth="65%"
								placeholder="Straße"
								label="Straße"
								name="street"
								type="text"
								defaultValue={this.state.houseInformation?.street}
							></Input>
							<Input
								maxWidth="35%"
								placeholder="Hausnummer"
								label="Hausnummer"
								name="houseNumber"
								type="text"
								defaultValue={this.state.houseInformation?.housenumber}
							></Input>

							<Input type="button" cType="CANCEL" name="" placeholder="Abbrechen" />
							<Input type="button" cType="SUBMIT" name="" placeholder="Aktualisieren" />
						</Form>
					)}
				</Dialog>
				<Dialog ref={this.addHouseDialogRef} closeable title="Haus Anlegen">
					<Form onSubmit={this.addHouse} onReset={this.addHouseDialogRef.current?.close}>
						<Input
							placeholder="Bezeichnung des Hauses"
							label="Name"
							name="name"
							type="text"
							defaultValue={this.state.houseInformation?.name}
						></Input>
						<Input maxWidth="65%" placeholder="Ort" label="Stadt" name="city" type="text"></Input>
						<Input maxWidth="35%" placeholder="Postleitzahl" label="PLZ" name="plz" type="text"></Input>
						<Input maxWidth="65%" placeholder="Straße" label="Straße" name="street" type="text"></Input>
						<Input maxWidth="35%" placeholder="Hausnummer" label="Hausnummer" name="houseNumber" type="text"></Input>

						<Input type="button" cType="CANCEL" name="" placeholder="Abbrechen" />
						<Input type="button" cType="SUBMIT" name="" placeholder="Anlegen" />
					</Form>
				</Dialog>
				<Dialog ref={this.deleteRef} closeable title="Bestätigung">
					<div className="delete-dialog">
						<div className="delete-text">Sind Sie sich sicher, dass Sie das Haus löschen möchten?</div>
						<div className="delete-footer">
							<Form onSubmit={this.deleteHouse} onReset={() => this.deleteRef.current?.close()}>
								<Input name="" type="button" cType="CANCEL" placeholder="Abbrechen" />
								<Input name="" type="button" cType="SUBMIT" />
							</Form>
						</div>
					</div>
				</Dialog>
				<div className="dashboard-top">
					<div className="dashboard-top-start">
						<IconButton
							icon="menu"
							type="transparent"
							className="dashboard-nav-button"
							onClick={() => this.setState({ navOpen: !this.state.navOpen })}
						></IconButton>
						<Input
							ref={this.houseInputRef}
							formChanged={this.houseChanged}
							icon="keyboard_arrow_down"
							name="house"
							type="searchselect"
							defaultValue={this.state.selectedHouse}
							options={this.state.houses}
							placeholder="Bitte wählen sie ein Haus aus!"
						></Input>
						<div id={"house-settings"} className={this.state.houseSettingsOpen ? "house-settings open" : "house-settings"}>
							<IconButton
								icon="more_vert"
								type="transparent"
								onClick={() => {
									this.housePopover.current?.toggle();
								}}
							></IconButton>
							<Popover parentId="house-settings" ref={this.housePopover} className="house-settings-popup">
								<Button
									text="Anlegen"
									onClick={() => {
										this.housePopover.current?.close();
										this.addHouseDialogRef.current?.toggle();
									}}
								></Button>
								<Button
									text="Bearbeiten"
									onClick={() => {
										this.housePopover.current?.close();
										this.editHouse();
									}}
								></Button>
								<Button
									text="Löschen"
									onClick={() => {
										this.housePopover.current?.close();
										this.deleteRef.current?.toggle();
									}}
								></Button>
							</Popover>
						</div>
					</div>
					<div className="dashboard-top-end">
						<div id="dashboard-user" className="dashboard-top-user">
							{this.state.user && (
								<div
									className="dashboard-top-user-image"
									onClick={() => {
										this.userPopover.current?.toggle();
									}}
								>
									<img src={this.state.user.picture} alt="User profile"></img>
								</div>
							)}
							<Popover parentId="dashboard-user" ref={this.userPopover} right className="dashboard-top-user-popup">
								<div className="user-header">
									<h2 className="user-name">{this.state.user.name}</h2>
									<p className="user-email">{this.state.user.email}</p>
								</div>
								<div className="user-content">
									<Button
										text="Mein Profil"
										onClick={() => {
											this.userPopover.current?.close();
											RouterHistory.push("/dashboard/profile");
										}}
									></Button>
									<Button
										text="Impressum"
										onClick={() => {
											this.userPopover.current?.close();
											this.impressumRef.current?.open();
										}}
									></Button>
									<Button
										text="Einstellungen"
										onClick={() => {
											this.userPopover.current?.close();
											RouterHistory.push("/dashboard/settings");
										}}
									></Button>
								</div>
							</Popover>
						</div>
					</div>
				</div>

				<div className="dashboard-dub">{<img src="/DUB.png" alt="Logo"></img>}</div>
				<div className="dashboard-left">
					<VerticalNavigation>
						<VerticalNavigationItem
							onClick={this.closeNav}
							history={RouterHistory}
							icon="home"
							to="/dashboard/home"
						></VerticalNavigationItem>
						<VerticalNavigationItem
							onClick={this.closeNav}
							history={RouterHistory}
							icon="apartment"
							to="/dashboard/apartments"
						></VerticalNavigationItem>
						<VerticalNavigationItem
							onClick={this.closeNav}
							history={RouterHistory}
							icon="people"
							to="/dashboard/renter"
						></VerticalNavigationItem>
						<CustomVerticalNavigationItem
							id="dashboard-user-nav-button"
							img={this.state.user.picture}
							position="BOTTOM"
							onClick={() => this.userNavPopover.current?.toggle()}
						></CustomVerticalNavigationItem>
						<VerticalNavigationItem history={RouterHistory} icon="logout" to="/logout" position="BOTTOM"></VerticalNavigationItem>
					</VerticalNavigation>
					<Popover parentId="dashboard-user-nav-button" ref={this.userNavPopover} end className="dashboard-top-user-popup">
						<div className="user-header">
							<h2 className="user-name">{this.state.user.name}</h2>
							<p className="user-email">{this.state.user.email}</p>
						</div>
						<div className="user-content">
							<Button
								text="Mein Profil"
								onClick={() => {
									this.userNavPopover.current?.close();
									this.closeNav();
									RouterHistory.push("/dashboard/profile");
								}}
							></Button>
							<Button
								text="Impressum"
								onClick={() => {
									this.userNavPopover.current?.close();
									this.closeNav();
									this.impressumRef.current?.open();
								}}
							></Button>
							<Button
								text="Einstellungen"
								onClick={() => {
									this.userNavPopover.current?.close();
									this.closeNav();
									RouterHistory.push("/dashboard/settings");
								}}
							></Button>
						</div>
					</Popover>
				</div>
				<div className="dashboard-content">
					{this.state.houses.length !== 0 && (
						<Switch>
							<Route exact path="/dashboard/home" component={Home} />
							<Route exact path="/dashboard/apartments" component={Apartments} />
							<Route exact path="/dashboard/renter" component={Renter} />

							<Route exact path="/dashboard/settings" component={Settings} />
							<Route exact path="/dashboard/profile" component={Profile} />
						</Switch>
					)}
					{this.state.houses.length === 0 && (
						<Switch>
							<Route
								exact
								path="/dashboard/home"
								render={() => {
									return (
										<BasePage title="Dashboard">
											<div className="error">Bitte Erstellen sie ein Haus!</div>
										</BasePage>
									);
								}}
							/>
							<Route
								exact
								path="/dashboard/apartments"
								render={() => {
									return (
										<BasePage title="Wohnungen">
											<div className="error">Bitte Erstellen sie ein Haus!</div>
										</BasePage>
									);
								}}
							/>
							<Route
								exact
								path="/dashboard/renter"
								render={() => {
									return (
										<BasePage title="Mieter">
											<div className="error">Bitte Erstellen sie ein Haus!</div>
										</BasePage>
									);
								}}
							/>
							<Route
								exact
								path="/dashboard/statistics"
								render={() => {
									return (
										<BasePage title="Statistiken">
											<div className="error">Bitte Erstellen sie ein Haus!</div>
										</BasePage>
									);
								}}
							/>

							<Route exact path="/dashboard/profile" component={Profile} />
							<Route exact path="/dashboard/settings" component={Settings} />
						</Switch>
					)}
				</div>
			</div>
		);
	}
}
