import { Button } from "@klumpp/tools";
import { Component } from "react";

export interface SettingsItemProps {
	title: string;
	onCancel?: () => void;
	onSave?: () => void;
	maxWidth?: string;
	noFooter?: boolean;
}

export interface SettingsItemState {}

export default class SettingsItem extends Component<SettingsItemProps, SettingsItemState> {
	render() {
		return (
			<div className="settings-item" style={{ maxWidth: this.props.maxWidth }}>
				<div className="settings-title">{this.props.title}</div>
				<div className="settings-content">{this.props.children}</div>
				{!this.props.noFooter && (
					<div className="settings-footer">
						<Button text="Abbrechen" onClick={this.props.onCancel} type="error"></Button>
						<Button text="Speichern" onClick={this.props.onSave} type="success"></Button>
					</div>
				)}
			</div>
		);
	}
}
