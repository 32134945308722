import {
	Cell,
	Dialog,
	Form,
	FormPager,
	HeaderCell,
	Input,
	OwnTable,
	RouterHistory,
	showNotification,
	Spinner,
	TableBody,
	TableHeader,
	TableRow,
} from "@klumpp/tools";
import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import { Apartment, HouseRenter, RenterContactInformation, Room } from "../../../types/ObjectTypes";
import "./Renter.scss";
import FileDownloader from "../../../utils/FileDownloader";
import dayjs from "dayjs";
import { formatDate } from "../../../utils/Utils";

export interface RenterProps {}

interface RenterInformation {
	userId: string;
	name: string;
	email: string;
	phonenumber: string;
	moveDate: number;
}

export interface RenterState {
	houseId?: string;
	loading: boolean;
	loadingRenter: boolean;
	renters: HouseRenter[];
	renterInformation?: RenterInformation;
	apartments: Apartment[];
	currentApartmentIndex: number;
	rooms: Room[][];
	renterContactInformation: RenterContactInformation;
	loadingContactInformation: boolean;
}

export default class Renter extends Component<RenterProps, RenterState> {
	state = {
		houseId: "",
		loading: true,
		loadingRenter: false,
		renters: [],
		renterInformation: undefined,
		apartments: [],
		rooms: [],
		currentApartmentIndex: -1,
		loadingContactInformation: false,
		renterContactInformation: {} as RenterContactInformation,
	} as RenterState;
	locListener: any;
	addRenterRef = React.createRef<Dialog>();
	editRenterRef = React.createRef<Dialog>();
	renterContactInformationRef = React.createRef<Dialog>();

	componentDidMount() {
		this.locListener = RouterHistory.listen((location) => {
			this.setState({ houseId: location.hash.replace("#", "") }, () => {
				this.loadRenters();
				this.loadApartments();
			});
		});
		this.setState({ houseId: window.location.hash.replace("#", "") }, () => {
			this.loadRenters();
			this.loadApartments();
		});
	}

	loadContactInformation = (renterId: string) => {
		this.setState({ loadingContactInformation: true }, () => {
			window.backend.getData(`/renters/contactInformation/${renterId}`, true).then((ci) => {
				ci.birthdate = dayjs(ci.birthdate).format("DD.MM.YYYY");
				this.setState({ loadingContactInformation: false, renterContactInformation: ci });
			});
		});
	};

	loadRenters = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData(`/house/renter/${this.state.houseId}`, true).then((renters) => {
				this.setState({ renters, loading: false });
			});
		});
	};

	loadApartments = (loading?: boolean) => {
		this.setState({ loading: loading === false ? false : true }, () => {
			window.backend.getData(`/apartments/available/${this.state.houseId}`, true).then((apartments) => {
				this.setState({ loading: false, apartments }, () => this.loadRooms());
			});
		});
	};

	loadRooms = async (cb?: () => void) => {
		const rooms = [];
		for (const apartment of this.state.apartments) {
			const room = await window.backend.getData(`/rooms/available/${apartment.id}`, true);

			rooms.push(room);
		}

		this.setState({ rooms });
	};

	createRenter = (values: any, reset: any) => {
		let tmp = values.birthdate.split(".");
		values.birthdate = new Date(`${tmp[2]}-${tmp[1]}-${tmp[0]} 00:00:00`).getTime();
		tmp = values.entryDate.split(".");
		values.entryDate = new Date(`${tmp[2]}-${tmp[1]}-${tmp[0]} 00:00:00`).getTime();

		const entryDate = values.entryDate;
		const apartmentId = values.apartmentId;
		const roomId = values.roomId;
		const persons = values.persons;
		const user = values;

		delete user.apartmentId;
		delete user.entryDate;
		delete user.roomId;
		delete user.persons;

		user.password = "initial1#Renter";
		window.backend.postData("/house/renter", { user, houseId: this.state.houseId, apartmentId, entryDate, roomId, persons }, true).then(() => {
			reset();
			this.loadRenters();
		});
	};

	editRenter = (values: any) => {
		this.setState({ loadingRenter: true }, () => {
			this.editRenterRef.current?.toggle();
			const renterInformation: RenterInformation = {
				userId: values[0],
				name: values[2],
				email: values[9],
				phonenumber: values[10],
				moveDate: values[8],
			};

			this.setState({ renterInformation: renterInformation, loadingRenter: false });
		});
	};

	currentEditUser?: any;

	updateRenter = (values: any) => {
		values.userId = this.currentEditUser[0];
		values.moveDate = formatDate(values.moveDate);
		this.setState({ loadingRenter: true }, () => {
			window.backend.putData(`/house/renter`, values, true).then(() => {
				this.currentEditUser = undefined;
				this.loadRenters();
				this.setState({ loadingRenter: false });
			});
		});
	};

	deleteRenter = (values: any) => {
		const apartmentId = values[1];
		const userId = values[0];

		const data = { houseId: this.state.houseId, apartmentId };

		window.backend.deleteData(`/house/renter/${userId}`, data, true).then(() => {
			this.loadRenters();
		});
	};

	onChangeApartment = (values: any) => {
		const index = this.state.apartments.findIndex((apartment) => apartment.id === Number(values.apartmentId));
		if (this.state.currentApartmentIndex !== index) {
			this.setState({ currentApartmentIndex: index ?? -1 });
		}
	};

	componentWillUnmount() {
		this.locListener && this.locListener();
	}

	createOCConclusion = (values: any[]) => {
		FileDownloader.openPDF(`${window.env.REACT_APP_API_URL as string}/house/oc/conclusion/${values[1]}/${values[0]}`, (err) => {
			showNotification({ msg: err, type: "E" });
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;

		const roomOptions =
			this.state.rooms[this.state.currentApartmentIndex]?.map((room) => {
				return { value: room.id + "", label: room.name };
			}) ?? [];

		roomOptions.unshift({ value: "UNSELECT", label: "Kein Raum" });
		return (
			<BasePage title="Mieter">
				<Dialog ref={this.renterContactInformationRef} closeable title="Kontaktinformationen">
					{this.state.loadingContactInformation && <Spinner></Spinner>}
					{!this.state.loadingContactInformation && (
						<Form onSubmit={() => this.renterContactInformationRef.current?.close()}>
							<Input
								label="Name"
								name="name"
								formnovalidate
								type="text"
								readonly
								defaultValue={this.state.renterContactInformation.name}
							></Input>
							<Input
								label="E-Mail"
								name="email"
								formnovalidate
								type="email"
								readonly
								defaultValue={this.state.renterContactInformation.email}
							></Input>
							<Input
								label="Telefonnummer"
								name="telephone"
								type="tel"
								formnovalidate
								readonly
								defaultValue={this.state.renterContactInformation.telephone}
							></Input>
							<Input
								label="Geburtsdatum"
								formnovalidate
								name="birthdate"
								type="date"
								readonly
								defaultValue={this.state.renterContactInformation.birthdate}
							></Input>
							<Input name="" type="button" cType="SUBMIT" placeholder="Schließen"></Input>
						</Form>
					)}
				</Dialog>
				<Dialog ref={this.addRenterRef} title="Mieter Anlegen" closeable>
					<FormPager
						onSubmit={(values, reset) => {
							this.createRenter(values, reset);
						}}
					>
						<Form title="Persönliche Daten">
							<Input name="firstName" label="Vorname" type="text" />
							<Input name="lastName" label="Nachname" type="text" />
							<Input name="email" label="E-Mail" type="email" />
							<Input name="telephone" label="Telefonnummer" type="tel" />
							<Input name="birthdate" label="Geburtstag" type="date" />
						</Form>

						<Form title="Wohnung" onChange={this.onChangeApartment}>
							<Input
								name="apartmentId"
								label="Name"
								type="searchselect"
								options={
									this.state.apartments?.map((apartment) => {
										return { value: apartment.id + "", label: apartment.name };
									}) ?? []
								}
							/>
							<Input name="entryDate" label="Einzugsdatum" type="date" />
							<Input name="roomId" label="Raum" type="searchselect" options={roomOptions} formnovalidate />
							<Input name="persons" label="Personenanzahl" type="number" defaultValue={1} />
						</Form>
					</FormPager>
				</Dialog>

				<Dialog ref={this.editRenterRef} title="Mieter Bearbeiten" closeable>
					{this.state.loadingRenter && <Spinner></Spinner>}
					{!this.state.loadingRenter && this.state.renterInformation && (
						<Form onReset={() => this.editRenterRef.current?.close()} title="Persönliche Daten" onSubmit={this.updateRenter}>
							<Input name="name" label="Name" type="text" defaultValue={this.state.renterInformation.name} />
							<Input name="email" label="E-Mail" type="email" defaultValue={this.state.renterInformation.email} />
							<Input name="telephone" label="Telefonnummer" type="tel" defaultValue={this.state.renterInformation.phonenumber} />
							<Input
								name="moveDate"
								label="Auszugsdatum"
								type="date"
								formnovalidate
								defaultValue={this.state.renterInformation.moveDate ?? ""}
							/>
							<Input name="" type="button" cType="CANCEL" placeholder="Abbrechen"></Input>
							<Input name="" type="button" cType="SUBMIT" placeholder="Aktualisieren"></Input>
						</Form>
					)}
				</Dialog>
				<OwnTable
					noDataText="Keine Daten"
					addButtonText="Anlegen"
					searchable
					propertyButtons={[
						{
							label: "Betriebskostenabrechnung exportieren",
							onClick: (values) => {
								this.createOCConclusion(values);
							},
						},
						{
							label: "Kontaktinformationen",
							onClick: (values) => {
								this.loadContactInformation(values[0]);
								this.renterContactInformationRef.current?.open();
							},
						},
						{
							label: "Bearbeiten",
							onClick: (values) => {
								this.currentEditUser = values;
								this.editRenter(values);
							},
						},
						{
							label: "Löschen",
							onClick: (values) => {
								this.deleteRenter(values);
							},
						},
					]}
					onAdd={() => {
						this.loadApartments(false);
						this.addRenterRef.current?.open();
					}}
				>
					<TableHeader>
						<HeaderCell minWidth={100} maxWidth="300px" align="CENTER">
							Name
						</HeaderCell>
						<HeaderCell minWidth={80} maxWidth="200px" align="CENTER">
							Wohnung
						</HeaderCell>
						<HeaderCell minWidth={80} maxWidth="200px" align="CENTER">
							Raum
						</HeaderCell>
						<HeaderCell minWidth={80} align="CENTER">
							Kaltmiete
						</HeaderCell>
						<HeaderCell minWidth={80} align="CENTER">
							Warmmiete
						</HeaderCell>
						<HeaderCell minWidth={80} align="CENTER">
							Fläche
						</HeaderCell>
						<HeaderCell minWidth={80} align="CENTER">
							Auszug
						</HeaderCell>
					</TableHeader>
					<TableBody>
						{this.state.renters?.map((renter, index) => (
							<TableRow key={index}>
								<Cell notVisible>{renter.userId}</Cell>
								<Cell notVisible>{renter.apartmentId}</Cell>
								<Cell>{renter.name}</Cell>
								<Cell>{renter.apartmentName}</Cell>
								<Cell>{renter.roomName ?? ""}</Cell>
								<Cell>{renter.coldRent}</Cell>
								<Cell>{renter.additionalCosts}</Cell>
								<Cell>{renter.size}</Cell>
								<Cell>{renter.moveDate ? dayjs(renter.moveDate).format("DD.MM.YYYY") : ""}</Cell>
								<Cell notVisible>{renter.email}</Cell>
								<Cell notVisible>{renter.telephone}</Cell>
							</TableRow>
						)) ?? []}
					</TableBody>
				</OwnTable>
			</BasePage>
		);
	}
}
