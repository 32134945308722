import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import SettingsItem from "./SettingsItem";
import "./Settings.scss";
import { Button, Form, Input, Spinner } from "@klumpp/tools";
import { LandlordData } from "../../../types/ObjectTypes";

export interface SettingsProps {}

export interface SettingsState {
	loading: boolean;
	loadingLandlord: boolean;
	landlordData: LandlordData;
}

export default class Settings extends Component<SettingsProps, SettingsState> {
	state = { loading: true, loadingLandlord: true, landlordData: {} } as SettingsState;

	loadSettings = () => {
		this.setState({ loading: false });
	};

	loadLandlordData = () => {
		this.setState({ loadingLandlord: true }, () => {
			window.backend.getData("/landlord", true).then((data) => {
				const dayMonth = data.billingStartDate.split("-");
				data.month = Number(dayMonth[0]) + 1;
				data.day = Number(dayMonth[1]);
				this.setState({ landlordData: data, loadingLandlord: false });
			});
		});
	};

	updateBillingDate = (values: any) => {
		values.month = values.month - 1;
		window.backend.putData("/landlord/billingDate", values, true).then(() => {
			this.loadLandlordData();
		});
	};

	componentDidMount() {
		this.loadSettings();
		this.loadLandlordData();
	}

	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		const cookiesAccepted = window.cookies.checkCookiesAccepted();
		return (
			<BasePage title="Allgemeine Einstellungen">
				<div className="settings">
					<SettingsItem title="Abrechnungsstart" noFooter>
						{this.state.loadingLandlord && <Spinner></Spinner>}
						{!this.state.loadingLandlord && (
							<Form onSubmit={(values) => this.updateBillingDate(values)}>
								<Input
									name="month"
									label="Monat"
									type="searchselect"
									maxWidth="200px"
									defaultValue={this.state.landlordData.month}
									options={[
										{ value: 1, label: "Januar" },
										{ value: 2, label: "Februar" },
										{ value: 3, label: "März" },
										{ value: 4, label: "April" },
										{ value: 5, label: "Mai" },
										{ value: 6, label: "Juni" },
										{ value: 7, label: "Juli" },
										{ value: 8, label: "August" },
										{ value: 9, label: "September" },
										{ value: 10, label: "Oktober" },
										{ value: 11, label: "November" },
										{ value: 12, label: "Dezember" },
									]}
								></Input>
								<Input name="day" maxWidth="100px" type="number" label="Tag" defaultValue={this.state.landlordData.day}></Input>
								<Input name="" type="button" placeholder="Abbrechen" cType="CANCEL"></Input>
								<Input name="" type="button" placeholder="Speichern" cType="SUBMIT"></Input>
							</Form>
						)}
					</SettingsItem>
					<SettingsItem maxWidth="300px" title="Cookies" noFooter>
						{cookiesAccepted && (
							<div className="cookies-setting-content">
								Wir verwenden Cookies, um Inhalte auf unserer Webseite zu personalisieren. Wenn sie dies nicht wollen klicken sie
								bitte auf "Cookies ablehnen".
							</div>
						)}
						{!cookiesAccepted && (
							<div className="cookies-setting-content">
								Wir verwenden Cookies, um Inhalte auf unserer Webseite zu personalisieren. Wenn sie die Cookies akzeptieren wollen,
								drücken sie bitte auf "Cookies akzeptieren".
							</div>
						)}
						<div className="cookies-setting-footer">
							{cookiesAccepted && (
								<Button
									type="error"
									text="Cookies ablehnen"
									onClick={() => {
										window.cookies.setCookie(window.cookies.accept_string, false);
										this.forceUpdate();
									}}
								></Button>
							)}
							{!cookiesAccepted && (
								<Button
									type="success"
									text="Cookies akzeptieren"
									onClick={() => {
										window.cookies.setCookie(window.cookies.accept_string, true);
										this.forceUpdate();
									}}
								></Button>
							)}
						</div>
					</SettingsItem>
				</div>
			</BasePage>
		);
	}
}
