type FileTypes = "pdf" | "other";

export default class FileDownloader {
	public static openPDF = async (pathToFile: string, errCb: (err: string) => void) => {
		FileDownloader.createDownloadLink(pathToFile, "pdf")
			.then((blobURL) => {
				window.open(blobURL);
			})
			.catch(async (err) => {
				const msg = await err;
				errCb(msg.data);
			});
	};
	public static downloadFile = async (pathToFile: string, fileName: string, type?: FileTypes) => {
		const anchor = document.createElement("a");
		document.body.appendChild(anchor);

		const objectUrl = await FileDownloader.createDownloadLink(pathToFile, type);

		anchor.href = objectUrl;
		anchor.download = fileName;
		anchor.click();

		setTimeout(() => {
			window.URL.revokeObjectURL(objectUrl);
		}, 0);
	};
	public static createDownloadLink = (pathToFile: string, type?: FileTypes) => {
		return new Promise<string>(async (resolve, reject) => {
			const headers = new Headers();
			headers.append("Authorization", `Bearer ${await window.auth.getAccessToken()}`);

			fetch(pathToFile, { headers })
				.then((response) => {
					if (response.ok) {
						return response.blob();
					} else {
						reject(response.json());
					}
				})
				.then((blobby) => {
					if (!blobby) return;
					let blob = blobby;
					if (type === "pdf") {
						blob = new Blob([blob], { type: "application/pdf" });
					}
					const objectUrl = window.URL.createObjectURL(blob);

					resolve(objectUrl);
				})
				.catch(reject);
		});
	};
}
